import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { BOOKINGS_FAIL, BOOKINGS_VIEW, BOOKINGS_SUCCESS } from "../type/types";
import { setLoading } from './LoadingAction';

// View Booking History
export const getBookingsData = (userRfId = "", rfId = 0, limit = 0, setIsLoading = false, onScroll = false) => async dispatch => {
    onScroll != true && setIsLoading(true)
    const token = getTokenLocalStorage();
    let formData = {
        "action": "View",
        "userRfId": userRfId > 0 && userRfId != undefined ? userRfId : "",
        "landOwnerRfId": "",
        "layoutRfId": "",
        "siteRfId": "",
        "order": "desc",
        "limit": limit == 0 ? "" : limit,
        "rfId": rfId == 0 ? "" : rfId,
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}sale`, formData, { headers }).then((response) => {
        // console.log(`getBookingsData response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch(setLoading(false));
            dispatch({
                payload: response,
                type: BOOKINGS_VIEW
            });
            setIsLoading(false);
        } else {
            onScroll != true && swal("Fail", "Unable to complete the request. Please try again later", "error");
            setIsLoading(false);
        }
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: BOOKINGS_SUCCESS
        });
        setIsLoading(false);
    })
}