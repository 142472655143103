import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { PAYMENT_REQUEST_SUCCESS, PAYMENT_REQUEST_FAIL } from "../type/types";

//Payment request 
export const paymentRequestSendUser = (bodyData, rfId, approvalType, setSending, notOnlyHandleClose) => async dispatch => {
    setSending(true);
    const token = getTokenLocalStorage();
    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    bodyData.loginUserId = loginUserId;
    bodyData.rfId = rfId;
    bodyData.approvalType = approvalType;

    const headers = { "x-auth-header": `${token}` }

    console.log(`paymentRequestSendUser bodyData After = ${JSON.stringify(bodyData)}`)

    axios.post(`${Url}approval-payment`, bodyData, { headers }).then((response) => {
        console.log(`paymentRequestSendUser response = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            swal("success", `Payment request is sent successfully!`, "success");
            dispatch({
                payload: response,
                type: PAYMENT_REQUEST_SUCCESS
            });
            notOnlyHandleClose();
        } else {
            swal("Fail", `Payment request is not sent, Please try again`, "error");
            dispatch({
                payload: response,
                type: PAYMENT_REQUEST_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: PAYMENT_REQUEST_FAIL
        });
        setSending(false);
    })
}
