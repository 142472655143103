import { CRO_ENTRY_DELETE, CRO_ENTRY_FAIL, CRO_ENTRY_INSERT, CRO_ENTRY_SUCCESS, CRO_ENTRY_UPDATE, CRO_ENTRY_VIEW } from "../type/types";
import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage } from '../../utils/Common';

// Get More Inputs Data
export const getCroEntry = (croType = 0, propertyRfId = 0) => async dispatch => {
    const token = getTokenLocalStorage();
    const formData = {
        "rfId": "",
        "action": "View",
        "userRfId": "",
        "croType": croType == 0 ? "" : croType,
        "propertyRfId": propertyRfId == 0 ? "" : propertyRfId,
        "order": "asc",
        "limit": ""
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}cro-register`, formData, { headers }).then((response) => {
        // console.log(`getCroEntry response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: CRO_ENTRY_VIEW
            })
        } else {
            dispatch({
                payload: response,
                type: CRO_ENTRY_VIEW
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: CRO_ENTRY_FAIL
        })
    })
}
// Add Add Cro Entry
export const addCroEntry = (formData, setSending) => async dispatch => {

    // console.log(`addCroEntry formData = ${JSON.stringify(formData)}`)
    setSending(true);
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    axios.post(`${Url}cro-register`, formData, { headers }).then((response) => {
        if (response?.data?.message === "Success") {
            swal("Success", `CRO entry updated for this Property`, "success");
            // history.goBack();
            dispatch({
                payload: response,
                type: CRO_ENTRY_INSERT
            });
            setSending(false);
        } else {
            // history.goBack();
            swal("Fail", `CRO entry is not updated for this property, Please try again`, "error");
            dispatch({
                payload: response,
                type: CRO_ENTRY_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: CRO_ENTRY_FAIL
        });
        setSending(false);
    })
}