import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Switch from "react-switch";
import UploadFile from '../Others/UploadFile';
import { useDispatch, useSelector } from "react-redux";
import { getBuildingApproval, updateBuildingApprovalList, addBuildingApproval } from '../../../../store/action/BuildingApprovalAction';
import { paymentRequestSendUser } from '../../../../store/action/PaymentAction';
import { Link, useHistory } from 'react-router-dom';
import BuildingApprovalDetailPage from './BuildingApprovalDetailPage';
import Loader2 from '../../../../utils/Loader2';

function EditBuildingApprovalStatus({ item, open, notOnlyHandleClose, onlyHandleClose }) {

    // console.log(`EditBuildingApprovalStatus item  = ${JSON.stringify(item)}`)
    const history = useHistory();
    const dispatch = useDispatch();

    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState({});
    const [finalDoc, setFinalDoc] = useState(item?.downloadDocument || null);
    const [bodyData, setBodyData] = useState({
        "rfId": "",
        "userRfId": "",
        "propertyRfId": "",
        "payment": 0,
        "statusRfId": 1,
        "loginUserId": "12",
        "remarks": "karthi",
        "action": "Insert"
    });

    const onChangeAmount = (name, value, item) => {
        setBodyData({
            ...bodyData,
            rfId: item?.paymentRfId,
            userRfId: item?.userRfId,
            propertyRfId: item?.rfId,
            payment: value
        })
    }

    const handleToggle = async (item, statusRfId, status) => {
        await dispatch(updateBuildingApprovalList(item?.rfId, statusRfId, status, 1, setSending, notOnlyHandleClose));
    };

    const onSubmitPaymentRequest = async (item) => {
        await dispatch(paymentRequestSendUser(bodyData, item?.paymentRfId, 1, setSending, notOnlyHandleClose));
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const onChangeUpload = async (name, value) => {
        await setFinalDoc(value)
        value.length > 0 && handleError("", name);
    }

    const validateUpdateStatus = async (item) => {
        let isValid = true;

        if (!finalDoc) {
            handleError('Required', 'finalDoc');
            isValid = false;
        }
        if (isValid) {
            const formData = item;
            formData.action = "Insert";
            formData.downloadDocument = finalDoc;
            await dispatch(addBuildingApproval(formData, history, setSending));
        }
    }

    return (
        <div>
            {sending && <Loader2 />}
            <Modal
                show={open}
                onHide={onlyHandleClose}
                backdrop="true"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Building Approval Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='DetailPage-container'>
                        <BuildingApprovalDetailPage item={item} />
                        {
                            item?.panchatStatus == 2 ?
                                <div className='d-flex flex-column align-items-center'>
                                    {
                                        item?.downloadDocument != "" ?
                                            <div className='d-flex flex-column align-items-center'>
                                                <p className='StatusContainer_text1 mt-5'>The request is completed & the file is already send to Land owner</p>
                                                <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#28d7a0', marginLeft: '10px' }}
                                                    onClick={() => window.open(item?.downloadDocument)}
                                                >
                                                    Download Document
                                                </Button>
                                            </div> :
                                            <div className='d-flex flex-column align-items-center'>
                                                <UploadFile
                                                    fileOnSet={onChangeUpload}
                                                    name="landDocument"
                                                    error={errors?.landDocument}
                                                >
                                                    Upload Land Documents
                                                </UploadFile>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB' }}
                                                        className="btn btn-info btn-pickLocation"
                                                        disabled={item?.downloadDocument != "" && true}
                                                        onClick={() => validateUpdateStatus(item)}
                                                    >
                                                        Send File
                                                    </Button>
                                                </div>
                                            </div>
                                    }
                                </div> :
                                <>
                                    {/* {
                                        sending && <CircularProgress size={15} sx={{ color: 'blue' }} />
                                    } */}

                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.uploadSuccess}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 1, newValue)}
                                            checked={item?.uploadSuccessStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                        />
                                        <p className='StatusContainer_text2'>{item?.uploadSuccessDateTime == null ? 'Pending..' : item?.uploadSuccessDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.documentPreparation}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 2, newValue)}
                                            checked={item?.documentPreparationStatusStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.uploadSuccessStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.documentPreparationStatusDateTime == null ? 'Pending..' : item?.documentPreparationStatusDateTime}</p>
                                    </div>
                                    {item?.documentPreparationStatusStatus == 2 &&
                                        <div className="Status_Container2">
                                            <input
                                                type="number"
                                                className="form-control form-input-holder"
                                                id="paymentAmount" name='paymentAmount'
                                                style={{ maxWidth: 250 }}
                                                placeholder="Enter Payment Amount"
                                                disabled={item?.paymentAmount != 0 && item?.paymentAmount != null && true}
                                                value={item?.paymentAmount == 0 ? bodyData?.payment?.toString() : item?.paymentAmount?.toString()}
                                                onChange={(e) => { onChangeAmount("payment", e.target.value, item) }}
                                            />
                                            <div className="col-md-2 btn-box">
                                                <Button
                                                    type="button"
                                                    style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB' }}
                                                    className="btn btn-info btn-pickLocation"
                                                    disabled={item?.paymentAmount != 0 && item?.paymentAmount != null && true}
                                                    onClick={() => onSubmitPaymentRequest(item)}
                                                >
                                                    Request
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.payment}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 3, newValue)}
                                            checked={item?.paymentStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.documentPreparationStatusStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.paymentStatusDateTime == null ? 'Pending..' : item?.paymentStatusDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.filePreparation}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 4, newValue)}
                                            checked={item?.filePreparationStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.paymentStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.filePreparationStatusDateTime == null ? 'Pending..' : item?.filePreparationStatusDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.submittedFile}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 5, newValue)}
                                            checked={item?.submittedFileStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.filePreparationStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.panchatStatusDateTime == null ? 'Pending..' : item?.panchatStatusDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.panchat}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 6, newValue)}
                                            checked={item?.panchatStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.submittedFileStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.panchatStatusDateTime == null ? 'Pending..' : item?.panchatStatusDateTime}</p>
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onlyHandleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditBuildingApprovalStatus