import axios from 'axios';

import { AUTH_ERROR, CLEAR_PROFILE, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, OTP_VERIFY, OTP_VERIFY_FAIL, REGISTER_FAIL, REGISTER_SUCCESS } from "../type/types";

import swal from 'sweetalert';
import { Url, saveTokenInLocalStorage, userLoginData } from '../../utils/Common';


// Otp Verification
export const OtpRequest = (mobileNo, registerType, setOtpLoading, setButtonShow) => async dispatch => {
    setOtpLoading(true)
    const formData = {
        action: "Otp Send",
        mobileNo: mobileNo,
        registerType: registerType
    }
    axios.post(`${Url}login`, formData).then((response) => {
        console.log(`response = ${JSON.stringify(response)}`);
        if (response?.data?.message === "Success") {
            swal(response?.data?.message, response?.data?.alert, "success");
            dispatch({
                payload: response,
                type: OTP_VERIFY
            })
            setOtpLoading(false);
            setButtonShow(true);
        } else {
            swal("Fail!", response?.data?.alert, "error");
            setOtpLoading(false);
            setButtonShow(false);
        }

    }).catch((err) => {
        swal("Fail", "Unable to complete the request. Please try again later", "error");
        dispatch({
            payload: err.response,
            type: OTP_VERIFY_FAIL
        })
        setOtpLoading(false);
        setButtonShow(false);
    })

}
// Login Verification
export const Login = (mobileNo, otp, registerType, history, setLoginLoading) => async dispatch => {
    setLoginLoading(true);
    const formData = {
        action: "Otp Verify",
        mobileNo: mobileNo,
        otp: otp,
        registerType: registerType
    }
    axios.post(`${Url}login`, formData).then((response) => {
        //console.log(`Login response = ${JSON.stringify(response)}`)
        if (response?.data?.message === "Success") {
            saveTokenInLocalStorage(response?.data?.token)
            userLoginData(response?.data?.data)
            dispatch({
                payload: response,
                type: LOGIN_SUCCESS
            })
            swal(response?.data?.message, "You have successfully logged in!", "success");
            setLoginLoading(false);
            history.push("/AdminHomeScreen")
        } else {
            swal("Oops!", "Something went wrong!", "error");
            setLoginLoading(false);
        }

    }).catch((err) => {
        swal("Fail", "Unable to complete the request. Please try again later", "error");
        dispatch({
            payload: err,
            type: LOGIN_FAIL
        });
        setLoginLoading(false);
    })
}

// Employee Sign In
export const MemberSignIn = (registerType, userName, companyName, mobileNo, history) => async dispatch => {
    const formData = {
        companyName: companyName,
        userName: userName,
        mobileNo: mobileNo,
        registerType: registerType,
        inputSource: "Android",
        loginUserId: "12",
        remarks: "karthi",
        action: "Sign Up"
    }
    axios.post(`${Url}login`, formData).then((response) => {
        if (response.data) {
            // saveTokenInLocalStorage(response?.data?.data?.token)
            // userLoginData(response?.data?.data?.member)
        }
        dispatch({
            payload: response,
            type: LOGIN_SUCCESS
        })
        history("/demo/")
    }).catch((err) => {
        dispatch({
            payload: err,
            type: LOGIN_FAIL
        })
    })
}