import React from 'react';
import { DtSentenceCase } from '../../../../utils/Common';
import ListOfProperty from '../Property/ListOfProperty';

export default function SurveyorDetailPage({ item }) {

    //console.log(`ConsultantDetailPage Data - ${JSON.stringify(item)}`)
    return (
        <>
            <div className='DetailPage-container'>
                <div className="row d-flex align-items-center">
                    <div className="col-md-3 my-3 d-flex align-items-center justify-content-center">
                        <img src={require('../../../../images/staff/staffImage.jpg')} alt="" style={{ width: 100, height: 100 }} />
                    </div>
                    <div className='col-md-9 text-left'>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Surveyor Name:  </span><span className='PropertyName-details'>{item.userName}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Mobile No:  </span><span className='PropertyName-details'>{item.mobileNo}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Address:  </span><span className='PropertyName-details'>{item.address}, {item?.blockName}, {DtSentenceCase(item.districtName)} - {item.pinCode}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Aadhaar No:  </span><span className='PropertyName-details'>{item.aadharNumber}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Pan Number:  </span><span className='PropertyName-details'>{item.panNumber}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Account No:  </span><span className='PropertyName-details'>{item.accountNumber}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>IFSC No:  </span><span className='PropertyName-details'>{item.ifscCode}</span>
                        </div>
                        <div className='mb-1'>
                            <span className='PropertyName-heading'>Service:  </span><span className='PropertyName-details'>{item.dtcpConsultantTypeData}</span>
                        </div>
                    </div>
                </div>
                {/* <ListOfProperty userRfId={item?.rfId} /> */}
            </div>
        </>
    )
}
