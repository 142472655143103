import React, { useEffect, useState } from 'react';
import { TiArrowBack } from 'react-icons/ti';
import { IoMdAddCircle } from 'react-icons/io';
import { getDistrictData, getSubZoneData, getDTCPApprovalData } from '../../../../store/action/BindAction';
import UploadFile from '../Others/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import PickLocation from '../Others/PickLocation';
import UploadImage from '../Others/UploadImage';
import { saveMarketingStaff } from '../../../../store/action/StaffAction';
import Collapsible from 'react-collapsible';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Button from '@mui/material/Button';
import Select from "react-select";

function AddSurveyor(props) {

    const data = props?.location?.state?.item
    const expertise = props?.expertise || false;

    let history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.BindReducer);

    const [moreOption, setMoreOption] = useState(false);
    const [errors, setErrors] = useState({});
    const [districtData, setDistrictData] = useState([]);
    const [subZoneData, setSubZoneData] = useState([]);
    const [dtcpData, setDtcpData] = useState([]);
    const [selectedDtcpData, setSelectedDtcpData] = useState([]);
    const [profileImg, setProfileImg] = useState([]);
    const [aadharCard, setAadharCard] = useState([]);
    const [panDoc, setPanDoc] = useState([]);
    const [chequeDoc, setChequeDoc] = useState([]);
    const [showMapModel, setShowMapModel] = useState(false);

    const [bodyData, setBodyData] = useState({
        rfId: data?.rfId || "",
        userName: data?.userName || "",
        mobileNo: data?.mobileNo?.toString() || "",
        whatsAppNumber: data?.whatsAppNumber != null ? data?.whatsAppNumber.toString() : "",
        stateId: data?.stateId || 1,
        blockId: data?.blockId || "",
        gender: data?.gender || 1,
        registerType: data?.registerType || 2,
        subMemberType: data?.subMemberType || 2,
        subMemberStatus: data?.subMemberStatus || 2,
        dtcpConsultantType: data?.dtcpConsultantType != "" && data?.dtcpConsultantType != null ? data?.dtcpConsultantType?.split(",").map(a => parseInt(a)) : [],
        address: data?.address || "",
        location: data?.location || "",
        districtId: data?.districtId || "",
        districtName: data?.districtName || "",
        pincode: data?.pincode || "",
        aadharNumber: data?.aadharNumber != null ? data?.aadharNumber?.toString() : "",
        panNumber: data?.panNumber != null ? data?.panNumber?.toString() : "",
        accountNumber: data?.accountNumber != null ? data?.accountNumber?.toString() : "",
        ifscCode: data?.ifscCode || "",
        loginUserId: data?.loginUserId || "",
        remarks: "",
        action: "Insert"
    });

    useEffect(() => {
        dispatch(getDistrictData());
        dispatch(getDTCPApprovalData());
    }, []);

    useEffect(() => {
        if (selector?.districtData?.data?.alert === "District Details") {
            setDistrictData(selector?.districtData?.data?.data);
        }
        if (selector?.subZoneData?.data?.alert === "Sub Zone Details") {
            setSubZoneData(selector?.subZoneData?.data?.data);
        }

        if (selector?.dtdcApprovalData?.data?.message === "Success") {
            const dtcpOptions = selector?.dtdcApprovalData?.data?.data?.map((res) => ({
                label: res.dataName,
                value: res.rfId,
            }));
            setDtcpData(dtcpOptions);

            if (bodyData.dtcpConsultantType.length > 0) {
                const selected = dtcpOptions
                    .filter((option) => bodyData.dtcpConsultantType.includes(option.value))
                    .map((option) => option.value);
                setSelectedDtcpData(selected);
            }
        }
    }, [selector.districtData, selector.subZoneData, selector.dtdcApprovalData]);

    const handleCheckboxChange = (value) => {
        let updatedConsultantService;
        if (selectedDtcpData.includes(value)) {
            updatedConsultantService = selectedDtcpData.filter((dtcpConsultantType) => dtcpConsultantType !== value);
        } else {
            updatedConsultantService = [...selectedDtcpData, value];
        }
        setSelectedDtcpData(updatedConsultantService);
        setBodyData((prevBodyData) => ({
            ...prevBodyData,
            dtcpConsultantType: updatedConsultantService
        }));
    };

    const onChangeLocation = (villageFinderData, address, location, pincode, districtId) => {
        // console.log(`villageFinderData = ${JSON.stringify(villageFinderData)}`)
        setBodyData({
            ...bodyData,
            latitude: villageFinderData?.latitude,
            longitude: villageFinderData?.longitude,
            address: address,
            location: location,
            pincode: pincode,
            districtName: villageFinderData?.district_name,
            districtId: districtId,
        });
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const { userName, mobileNo, whatsAppNumber, address, location, districtId, blockId, pincode, gender, aadharNumber, panNumber, ifscCode, accountNumber } = bodyData;

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.userName || bodyData.userName.length < 3) {
            handleError('Name must be min 3 character long', 'userName');
            isValid = false;
        }
        if (
            !bodyData.mobileNo ||
            isNaN(bodyData.mobileNo) ||
            (bodyData.mobileNo.toString().length !== 10 ||
                bodyData.mobileNo.toString().includes('.'))
        ) {
            handleError('Enter valid number', 'mobileNo');
            isValid = false;
        }
        if (
            !bodyData.whatsAppNumber ||
            isNaN(bodyData.whatsAppNumber) ||
            (bodyData.whatsAppNumber.toString().length !== 10 ||
                bodyData.whatsAppNumber.toString().includes('.'))
        ) {
            handleError('Enter valid number', 'whatsAppNumber');
            isValid = false;
        }

        if (!bodyData.districtId) {
            handleError('Select Zone', 'districtId');
            isValid = false;
        }

        if (!bodyData.blockId) {
            handleError('Select Sub Zone', 'blockId');
            isValid = false;
        }

        if (!bodyData.dtcpConsultantType || bodyData.dtcpConsultantType.length === 0) {
            handleError('Select Nature of service', 'dtcpConsultantType');
            isValid = false;
        }

        if (isValid) {
            AddHandler();
        }
    }

    const AddHandler = async () => {
        await dispatch(saveMarketingStaff(bodyData, history, expertise))
    }

    const onChangeZoneBasedData = async (districtId) => {
        // console.log(`District ID = ${districtId}`)
        setBodyData({ ...bodyData, districtId: districtId });
        // setErrors(prevState => ({ ...prevState, districtId: '' }));
        setBodyData(prevState => ({ ...prevState, blockId: '' }));
        setSubZoneData([])
        await dispatch(getSubZoneData(districtId));

    };

    const toggleCollapsible = () => {
        setMoreOption(!moreOption);
    };

    return (
        <>
            <div className='d-flex mt-2'>
                {expertise != true &&
                    <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                }
                <p className='listings-headingWithBack'>{bodyData?.rfId !== "" ? "Update Surveyor" : "Add Surveyor"}</p>
            </div>

            <form className="row g-3 property-form ml-2 mr-2 mt-3"
                onSubmit={validate}
                noValidate
            >
                <div className="col-md-6">
                    {errors.userName ?
                        <label htmlFor="userName" className="error-text required">{errors.userName}</label> :
                        <label htmlFor="userName" className="text-black">Surveyor Name<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="userName" name='userName'
                        value={userName}
                        onChange={e => onChange(e)}
                        placeholder="Enter Applicant Name"
                        noValidate
                        onFocus={() => handleError(null, 'userName')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.mobileNo ?
                        <label htmlFor="mobileNo" className="error-text">{errors.mobileNo}</label> :
                        <label htmlFor="mobileNo" className="text-black">Mobile Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="mobileNo" name='mobileNo'
                        value={mobileNo}
                        maxLength={10}
                        onChange={e => onChange(e)}
                        placeholder="Enter Mobile Number"
                        noValidate
                        onFocus={() => handleError(null, 'mobileNo')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.whatsAppNumber ?
                        <label htmlFor="whatsAppNumber" className="error-text">{errors.whatsAppNumber}</label> :
                        <label htmlFor="whatsAppNumber" className="text-black">Whatsapp Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="whatsAppNumber" name='whatsAppNumber'
                        value={whatsAppNumber}
                        maxLength={10}
                        onChange={e => onChange(e)}
                        placeholder="Enter Whats App Number"
                        noValidate
                        onFocus={() => handleError(null, 'whatsAppNumber')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.districtId ?
                        <label htmlFor="districtId" className="error-text required">{errors.districtId}</label> :
                        <label htmlFor="districtId" className="text-black">Zone<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="districtId" style={{ fontSize: '14px' }}
                        value={bodyData?.districtId}
                        onChange={e => onChangeZoneBasedData(e.target.value)}
                        onFocus={() => handleError(null, 'districtId')}>
                        <option value=""> Select Zone </option>
                        {
                            districtData.length > 0 && districtData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.districtName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-6">
                    {errors.blockId ?
                        <label htmlFor="blockId" className="error-text required">{errors.blockId}</label> :
                        <label htmlFor="blockId" className="text-black">Sub Zone<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="blockId" style={{ fontSize: '14px' }}
                        value={blockId}
                        onChange={e => setBodyData({ ...bodyData, blockId: e.target.value })}
                        onFocus={() => handleError(null, 'blockId')}>
                        <option value=""> Select Sub Zone </option>
                        {
                            subZoneData?.length > 0 && subZoneData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.subZoneName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-6">
                    <fieldset className="mb-3 mt-4 d-flex align-items-center">
                        <label className="form-label mr-5">Gender</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input mt-1" type="radio" name="gender" id="gender" value="1" onChange={e => onChange(e)} checked={gender == 1 && 1} />
                                <label className="form-check-label" htmlFor="gridRadios1">
                                    Male
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input mt-1" type="radio" name="gender" id="gender" value="0" onChange={e => onChange(e)} checked={gender == 0 && 1} />
                                <label className="form-check-label" htmlFor="gridRadios2">
                                    Female
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-12">
                    {errors.dtcpConsultantType ?
                        <label htmlFor="dtcpConsultantType" className="error-text required">{errors.dtcpConsultantType}</label> :
                        <label htmlFor="dtcpConsultantType" className="text-black">Nature of Service<span className="required">*</span> </label>
                    }
                    <div className="grid-container">
                        {dtcpData.length > 0 &&
                            dtcpData.map((dtcpConsultantType, index) => (
                                <div className="grid-item" key={index}>
                                    <input
                                        type="checkbox"
                                        id={dtcpConsultantType.value}
                                        value={dtcpConsultantType.value}
                                        checked={selectedDtcpData.includes(dtcpConsultantType.value)}
                                        onChange={() => handleCheckboxChange(dtcpConsultantType.value)}
                                    />
                                    <label htmlFor={dtcpConsultantType.value} style={{ marginLeft: '8px', marginTop: '7px' }}>
                                        {dtcpConsultantType.label}
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <Button
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', backgroundColor: '#fdb447', color: 'white' }}
                        onClick={toggleCollapsible}
                    >
                        More Option
                        {moreOption ? <FaAngleUp style={{ marginLeft: '5px' }} /> : <FaAngleDown style={{ marginLeft: '5px' }} />}
                    </Button>
                </div>
                <Collapsible trigger="" open={moreOption}>
                    <div className='row property-form'>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="address" className="text-black">Address</label>
                            <input
                                type="text"
                                className="form-control form-input-holder"
                                id="address" name='address'
                                value={bodyData?.address}
                                onChange={e => onChange(e)}
                                placeholder="Enter Address"
                                noValidate
                            />
                        </div>
                        <div className="col-md-2 btn-box 4">
                            <button type="button" className="btn btn-info btn-pickLocation" onClick={() => setShowMapModel(!showMapModel)}>
                                Pick Location
                            </button>
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="location" className="text-black">Location</label>
                            <input
                                type="text"
                                className="form-control form-input-holder"
                                id="location" name='location'
                                value={bodyData?.location}
                                onChange={e => onChange(e)}
                                placeholder="Location"
                                noValidate
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="districtId" className="text-black">District Name</label>
                            <select className="form-select" name="districtId"
                                value={districtId}
                                onChange={e => onChange(e)}
                                style={{ fontSize: '14px' }}
                            >
                                <option value=""> Select District </option>
                                {
                                    districtData.length > 0 && districtData?.map((data, index) => {
                                        return <option key={index} value={data.rfId}> {data.districtName} </option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-2 mb-4">
                            <label htmlFor="pincode" className="text-black">Pincode</label>
                            <input
                                type="number"
                                className="form-control form-input-holder"
                                id="pincode" name='pincode'
                                value={bodyData?.pincode}
                                onInput={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                }}
                                onChange={e => onChange(e)}
                                placeholder="Enter Pincode"
                                noValidate
                                onFocus={() => handleError(null, 'pincode')}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="aadharNumber" className="text-black">Aadhaar Number</label>
                            <input
                                type="text"
                                className="form-control form-input-holder"
                                id="aadharNumber" name='aadharNumber'
                                value={aadharNumber}
                                onChange={e => onChange(e)}
                                placeholder="Enter Aadhaar Number"
                                noValidate
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="panNumber" className="text-black">Pan Number</label>
                            <input
                                type="text"
                                className="form-control form-input-holder"
                                id="panNumber" name='panNumber'
                                value={panNumber}
                                onChange={e => onChange(e)}
                                placeholder="Enter Pan Number"
                                noValidate
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="ifscCode" className="text-black">IFSC Code</label>
                            <input
                                type="text"
                                className="form-control form-input-holder"
                                id="ifscCode" name='ifscCode'
                                value={ifscCode}
                                onChange={e => onChange(e)}
                                placeholder="Enter IFSC Code"
                                noValidate
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="accountNumber" className="text-black">Account Number</label>
                            <input
                                type="text"
                                className="form-control form-input-holder"
                                id="accountNumber" name='accountNumber'
                                value={accountNumber}
                                onChange={e => onChange(e)}
                                placeholder="Enter Account Number"
                                noValidate
                            />
                        </div>
                    </div>
                    <div className='upload-conatiner-postProperty'>
                        <UploadFile setDocs={setAadharCard} docs={aadharCard} >Upload Aadhaar Card</UploadFile>
                        <UploadFile setDocs={setPanDoc} docs={panDoc} >Upload Pan Card</UploadFile>
                        <UploadFile setDocs={setChequeDoc} docs={chequeDoc}>Upload Cancelled Cheque</UploadFile>
                        <UploadImage setFile={setProfileImg} file={profileImg}>Upload Profile Image</UploadImage>
                    </div>
                </Collapsible>
                <Modal
                    show={showMapModel}
                    onHide={() => setShowMapModel(!showMapModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Google Map</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PickLocation onChangeLocation={onChangeLocation} districtData={districtData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowMapModel(!showMapModel)}>
                            Confirm Location
                        </Button>
                        <Button variant="secondary" onClick={() => setShowMapModel(!showMapModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="col-md-12 text-center my-5">
                    <button className="btn btn-primary px-5" type="submit">{bodyData?.rfId !== "" ? "Update Surveyor" : "Add Surveyor"}</button>
                </div>
            </form>
        </>
    )
}

export default AddSurveyor