import React from 'react'
import img2 from '../../images/get-connected/img2.png'
import img3 from '../../images/get-connected/img3.png'
import img4 from '../../images/get-connected/img4.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function GetConnected() {
    return (
        <div className="container container-connect mb-5" style={{ backgroundImage: "url(" + img4 + ")", backgroundPosition: "center", backgroundRepeat: 'no-repeat' }}>
            <LazyLoadImage
                src={img3}
                className='get-connected-img1'
                effect="blur"
                wrapperProps={{
                    style: { transitionDelay: "1s" },
                }}
            />
            <LazyLoadImage
                src={img2}
                className='get-connected-img2'
                effect="blur"
                wrapperProps={{
                    style: { transitionDelay: "1s" },
                }}
            />
            <LazyLoadImage
                src={img2}
                className='get-connected-img3'
                effect="blur"
                wrapperProps={{
                    style: { transitionDelay: "1s" },
                }}
            />
            <div className="row row-con-get-connect p-4">
                <div className="col-lg-9 col-md-8 col-sm-12 get-connect-text">
                    <h3 className='text-white get-connect-text-h'>Get Connected with top agents <br /> near you and find your home</h3>
                    <p className='mt-1 get-connect-text-p'>Get in touch or create account. We are 24/7 available</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 my-auto mx-auto d-block text-center" >
                    <button className='btn btn-primary btn-custom-agent mb-3'>Find Agent</button>
                </div>
            </div>
        </div>
    )
}
