import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { MEMBER_DELETE, MEMBER_FAIL, MEMBER_INSERT, MEMBER_SUCCESS, MEMBER_UPDATE, MEMBER_VIEW, STAFF_SUCCESS, STAFF_FAIL } from "../type/types";
import { setLoading } from './LoadingAction';
import { getGlobalSearchData } from './GlobalSearchAction';

// Get Member Insert
export const memberRegistration = (formData) => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        //console.log(`response - ${JSON.stringify(response)}`)
        swal("Success", "Email already exists", "success");
        dispatch({
            payload: response,
            type: MEMBER_INSERT
        })
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: MEMBER_FAIL
        })
    })
}

//land owner register
export const memberSignUp = (formData, history) => async dispatch => {

    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    let formDatas = formData;
    formDatas.loginUserId = loginUserId

    console.log(`formDatas = ${JSON.stringify(formDatas)}`)
    await axios.post(`${Url}login`, formDatas).then(async (response) => {

        if (response?.data?.message == "Success") {
            swal("Success", "Land Owner Added", "success");
            history.push("/LandOwners")
            dispatch({
                payload: [],
                type: MEMBER_VIEW
            })
            await dispatch(getMemberData(2))
        } else {
            swal("Fail", "Land Owner Not Added", "error");
        }

    }).catch((error) => {
        console.log(error)
    })
}

// Get Member Data
export const getMemberData = (registerType = 0, mobileNo = 0, rfId = 0, limit = 0, setIsLoading = false, onScroll = false) => async dispatch => {

    onScroll != true && setIsLoading(true)
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "registerType": registerType == 0 ? "" : registerType,
        "rfId": rfId == 0 ? "" : rfId,
        "limit": limit == 0 ? "" : limit,
        "order": "",
        "mobileNo": mobileNo == 0 ? "" : mobileNo,
        "userName": "",
        "FromDate": "",
        "ToDate": "",
        "districtId": "",
        "blockId": "",
        "stateId": "",
        "type": ""
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        // console.log(`getMemberData response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            //dispatch(setLoading(false));
            dispatch({
                payload: response,
                type: MEMBER_VIEW
            });
            mobileNo != 0 && dispatch(getGlobalSearchData(response?.data?.data[0]?.rfId, mobileNo))
            setIsLoading(false);
        } else {
            onScroll != true && swal("Fail", "Unable to complete the request. Please try again later", "error");
            setIsLoading(false);
        }
    }).catch((err) => {
        // dispatch(setLoading(false));
        onScroll != true && swal("Fail", "Unable to complete the request. Please try again later", "error");
        dispatch({
            payload: err.response,
            type: MEMBER_FAIL
        });
        setIsLoading(false);
    })
}