import React from 'react';

const ConsultantCondition = () => {

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="jumbotron">
                            <h1>  Consultant Registration: </h1>
                            <h2>  Dear Consultant, </h2>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We're excited to have you join the My Property QR as a registered Consultant. To ensure a positive experience for all users, we kindly ask you to carefully review and acknowledge the following undertakings. These undertakings outline your responsibilities and our expectations regarding your profile registration on the platform.</p>
                            <h2>*Undertakings:*</h2>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. *Accurate Profile Information:*
                                You confirm that all information provided in your profile is accurate, complete, and up-to-date. This includes your professional credentials, contact details, and any other relevant information.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. *Professional Qualifications:*
                                You certify that you possess the necessary qualifications, licenses, and expertise required for real estate agency services. Misrepresentation of your qualifications may lead to the removal of your profile from the platform.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. *Client Relationships:* You agree to establish and maintain professional relationships with clients and users of the platform. Any engagements or interactions should be conducted with integrity, honesty, and transparency.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. *Timely Responses:* You understand the importance of timely responses to user inquiries and requests. Prompt communication is crucial to maintaining a positive reputation on the platform.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. *Compliance with Laws:*
                                You agree to adhere to all applicable laws, regulations, and industry standards while using the platform. This includes complying with fair housing laws and data privacy regulations.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. *Listing Accuracy:*
                                If you list properties on the platform, you are responsible for ensuring the accuracy of property details, pricing, and availability. Misleading information may harm your reputation and the platform's credibility.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. *Conflict of Interest:* You will not engage in any activities that create a conflict of interest or compromise the trust users place in you as their Consultant.
                            </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. *Liability Limitation:* You understand that the My Property QR is not liable for any damages, direct or indirect, arising from your profile registration, interactions, or services provided on the platform.
                            </p>
                            <h2>Acknowledgement</h2>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By proceeding with the registration of your profile on the My Property QR, you acknowledge that you have read and understood the above undertakings. You agree to abide by these terms and recognize that your profile registration is subject to the limitations and responsibilities outlined within these undertakings.
                                If you do not agree with any part of these undertakings, we kindly ask you not to register your profile on the platform.
                                If you have any questions or concerns regarding these undertakings or the profile registration process, please contact us at enquiry@mypropertyqr.com
                                Thank you for your cooperation and for becoming a valued Consultant on the My Property QR.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>)

}

export default ConsultantCondition;