import React from 'react'
import ControlsListTable from './ControlsListTable'

export default function ControlsHome() {
    return (
        <>
            <p className='listings-heading'>Controls</p>
            <ControlsListTable />
        </>
    )
}
