import zIndex from '@mui/material/styles/zIndex';
import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

function Loader2() {
    return (
        <div style={styles.overlay}>
            <ThreeCircles
                visible={true}
                height="50"
                width="50"
                color="#bcd7ff"
                ariaLabel="three-circles-loading"
            />
        </div>
    );
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2000
    },
};

export default Loader2;
