import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

var img1 = require('./../../images/city/pic1.jpg');
var img2 = require('./../../images/city/pic2.jpg');
var img3 = require('./../../images/city/pic3.jpg');
var img4 = require('./../../images/city/pic4.jpg');
var img5 = require('./../../images/city/pic5.jpg');
var img6 = require('./../../images/city/pic6.jpg');
var img7 = require('./../../images/city/pic7.jpg');
var img8 = require('./../../images/city/pic8.jpg');

export default function FeatureCities() {
	return (
		<div className="section-full content-inner">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 text-center">
						<p style={{ fontSize: '32px', color: 'black', fontWeight: 'bolder' }}>Featured Cities</p>
						<p style={{ fontSize: '20px', color: 'black' }}>20+ Cities Added</p>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img1}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center  pt-2'>Erode</h6>
					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img2}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center   pt-2'>Chennai</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img3}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center  pt-2'>Salem</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img4}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center   pt-2'>Coimbatore</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img5}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center pt-2 '>Kanchipuram</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img6}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center pt-2 '>Madurai</h6>

					</div>
					<div className="col-lg-3 col-sm-6 col-md-6 m-b30">
						<LazyLoadImage
							src={img7}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center pt-2 '>Nagapattinam</h6>

					</div>
					<div className="col-lg-3 col-sm-12 col-md-6 m-b30">
						<LazyLoadImage
							src={img8}
							className='city-bx img-fluid mx-auto d-block'
							effect="blur"
							wrapperProps={{
								style: { transitionDelay: "1s" },
							}}
						/>
						<h6 className='text-center pt-2 '>Krishnagiri</h6>
					</div>
				</div>
			</div>
		</div >
	)
}
