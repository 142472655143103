import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Autocomplete, Circle, InfoWindow, Marker } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../../../utils/Common';
import { useDispatch, useSelector } from "react-redux";
import { getLayoutData } from '../../../../store/action/LayoutAction';
import { Modal } from 'react-bootstrap';
import PropertyDetailPage from '../Property/PropertyDetailPage';
import Button from '@mui/material/Button';
import { MdOutlineMyLocation } from "react-icons/md";
import RateUnit from '../../../../utils/RateUnit';
import { GiIsland } from "react-icons/gi";
import { MdMapsHomeWork } from "react-icons/md";
import { RiRoadMapFill } from "react-icons/ri";

const center = {
    lat: 11.342423,
    lng: 77.728165
};

function PlotFinderHome() {

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);

    const [layoutData, setLayoutData] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(center);
    const [modelData, setModelData] = useState({});
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState();
    const mapRef = useRef(null);
    const geocoder = useRef(null);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await dispatch(getLayoutData("", 0, 0, 0, 0, 10, "", "", "", "", "", "", "", setIsLoading, ""));
    }

    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            setLayoutData(selector?.payload?.data?.data)
        }
    }, [selector?.payload?.data])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places']
    });

    const onLoad = React.useCallback((map) => {
        setMap(map);
        mapRef.current = map;
        geocoder.current = new window.google.maps.Geocoder();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setCurrentPosition(currentPosition);
                    mapRef.current.panTo(currentPosition);
                },
                (error) => {
                    console.error("Error getting current position:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    const onUnmount = React.useCallback((map) => {
        setMap(null);
    }, []);

    const onLoadAutocomplete = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                mapRef.current.panTo(location);
                setCurrentPosition(location);
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const handleLocationButtonClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setCurrentPosition(currentPosition);
                    mapRef.current.panTo(currentPosition);
                },
                (error) => {
                    console.error("Error getting current position:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    const ViewHandler = (data) => {
        setModelData(data)
        setViewModalOpen(true);
    }

    const viewCloseHandler = () => setViewModalOpen(false);

    const customIconUrl = layoutData?.layoutType == 1 ? 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' : layoutData?.layoutType == 2 ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';

    return isLoaded ? (
        <div className='model-content-container VillageFinder'>
            <GoogleMap
                mapContainerStyle={{ width: '96%', height: '95%' }}
                center={currentPosition}
                zoom={13}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                }}
            >
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type="text"
                        placeholder="Enter a location"
                        className="autocomplete-input"
                    />
                </Autocomplete>
                {currentPosition && (
                    <>
                        <Circle
                            center={currentPosition}
                            radius={1100}
                            options={{
                                strokeWeight: 0,
                                fillColor: '#bad3ff',
                                fillOpacity: 0.9,
                            }}

                        />
                        <Circle
                            center={currentPosition}
                            radius={700}
                            options={{
                                strokeWeight: 0,
                                fillColor: '#9fc5ff',
                                fillOpacity: 0.9,
                            }}
                        />
                        <Circle
                            center={currentPosition}
                            radius={400}
                            options={{
                                strokeWeight: 0,
                                fillColor: '#75acff',
                                fillOpacity: 0.9,
                            }}
                        />
                        <Circle
                            center={currentPosition}
                            radius={100}
                            options={{
                                strokeWeight: 0,
                                fillColor: '#1E90FF',
                                fillOpacity: 0.9,
                            }}
                        />
                    </>
                )}
                {layoutData.map((item, index) => (
                    <Marker
                        key={index}
                        position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
                        onClick={() => setSelectedMarker(item)}
                        icon={item?.layoutType == 1 ? 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' : item?.layoutType == 2 ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'}
                        opacity={item?.layoutStatus === 1 ? 1 : 0.4}
                    />
                ))}
                {selectedMarker && (
                    <InfoWindow
                        position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
                        onCloseClick={() => setSelectedMarker(null)}
                    >
                        <div className='toolTip_Container' onClick={() => ViewHandler(selectedMarker)}>
                            {selectedMarker?.layoutType == 1 ? <RiRoadMapFill color='yellow' style={{ width: 21, height: 21, marginRight: 5 }} /> : selectedMarker?.layoutType == 2 ? <MdMapsHomeWork color='yellow' style={{ width: 21, height: 21, marginRight: 5 }} /> : <GiIsland color='yellow' style={{ width: 21, height: 21, marginRight: 5 }} />}
                            <div style={{ color: 'yellow' }}>{RateUnit(selectedMarker)}</div>

                        </div>
                    </InfoWindow>
                )}
                {/* {layoutData.map((item, index) => (
                    <InfoWindow
                        key={index}
                        position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}

                    >
                        <div style={{ backgroundColor: '#3786fb', padding: 8 }} onClick={() => ViewHandler(item)}>
                            <div style={{ color: 'white' }}>rfId: {item.rfId}</div>
                            <div style={{ color: 'white' }}>Price: {item.contactNumber}</div>
                        </div>
                    </InfoWindow>
                ))} */}
                <button
                    className="custom-map-control-button"
                    onClick={handleLocationButtonClick}
                >
                    <MdOutlineMyLocation style={{ width: 25, height: 25, color: 'grey' }} />
                </button>
            </GoogleMap>
            <Modal
                show={viewModalOpen}
                onHide={viewCloseHandler}
                backdrop="true"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Property Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PropertyDetailPage item={modelData} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={viewCloseHandler}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    ) : <></>;
}

export default React.memo(PlotFinderHome);
